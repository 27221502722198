<script lang="ts" setup>
import type { KeenSliderOptions, KeenSliderInstance } from "keen-slider";

import type { JackpotValue } from "@/types";

const { t } = useT();
const { data: appInit } = useAppInitData();
const { pageData } = useHomePage();
const { open } = useTaoModals();
const { isOneClickRegister } = useOtpGetFlow();

const { isSweepStakes } = useSwitchMode();

const loggedIn = computed(() => !appInit.value?.isGuest);

const currency = computed(() => appInit.value?.currency || "USD");

const values = computed(
	() => (pageData?.value?.jackpots?.[isSweepStakes.value ? "USD" : "TRN"]?.values || []) as JackpotValue
);

const allWin = computed(() =>
	Array.isArray(values.value) ? values.value.reduce((acc, num) => acc + Number(num.amount) * 100, 0).toFixed(0) : "0"
);

const reverseValues = computed(() => {
	const valuesCopy = values.value ? [...values.value] : [];
	return valuesCopy?.reverse();
});

const sliderActiveSlide = ref(0);

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto", spacing: 12 },
	slideChanged(slider: KeenSliderInstance) {
		sliderActiveSlide.value = slider.track.details.abs;
	}
};

const clickHandler = () => {
	if (!loggedIn.value) {
		if (isOneClickRegister) {
			open("LazyOModalOneClickSignup");
			return;
		}
		open("LazyOModalSignup", { location: "jackpots" });
	} else {
		open("LazyOModalJackpotsGames");
	}
};
</script>

<template>
	<div class="jackpots" :class="{ 'jackpots--logged-in': loggedIn, 'jackpots--sweeps': isSweepStakes }">
		<div class="jackpot-header">
			<AText class="title" type="h7">{{ t("total jackpot") }}</AText>
			<AText
				class="jackpot-all-win"
				:class="isSweepStakes && 'jackpot-all-win--sweep'"
				type="h2 h1-xl"
				data-tid="jackpot-value"
			>
				<ASvg :name="isSweepStakes ? '12/secret-coins' : '12/coins'" class="icon" />
				{{ numberFormat(+allWin) || currency }}
			</AText>
			<div class="jackpot__footer">
				<AButton
					class="jackpot__footer-button"
					variant="primary"
					size="xl"
					data-tid="jackpot-sing-in"
					@click="clickHandler"
				>
					<div v-if="!loggedIn">
						{{ t("Sign Up") }}
						<AText as="div" :modifiers="['normal']">{{ t("It only takes 1 minute") }}</AText>
					</div>
					<template v-else>{{ t("hit now") }}</template>
				</AButton>
			</div>
		</div>
		<div class="jackpots-block jackpots-block--desk">
			<OJackpotItem
				v-for="(item, index) in reverseValues"
				:key="index"
				class="jp-list-item"
				:class="{
					'jp-list-item--diamond': !index
				}"
				:jackpot="item as unknown as JackpotValue[number]"
				:isSweepStakes="isSweepStakes"
			/>
		</div>

		<div class="jackpots-block jackpots-block--mob">
			<ASlider :options="sliderOptions">
				<template #dots="{ slider }">
					<div
						v-if="slider && reverseValues.length > 1"
						class="pagination"
						:class="isSweepStakes && 'pagination--swipes'"
					>
						<div
							v-for="(_slide, idx) in reverseValues"
							:key="idx"
							:class="{ dot: true, 'dot-active': sliderActiveSlide === idx }"
							@click="slider?.moveToIdx(idx)"
						/>
					</div>
				</template>
				<div v-for="(jackpotItem, i) in reverseValues" :key="i" class="swiper-slide keen-slider__slide">
					<OJackpotItem
						class="jp-list-item"
						:class="{
							'jp-list-item--diamond': !i
						}"
						:jackpot="jackpotItem as unknown as JackpotValue[number]"
						:isSweepStakes="isSweepStakes"
					/>
				</div>
			</ASlider>
		</div>
	</div>
</template>

<style lang="scss" scoped>
:deep {
	.keen-slider {
		overflow: visible !important;
		z-index: 5;
	}

	.swiper-slide {
		width: 275px !important;
		flex-shrink: 0;
		max-width: 100%;

		&:not(:last-child) {
			margin-right: 5px;
		}
	}
}

.icon {
	font-size: 40px;
}

.jackpot__footer-button {
	display: flex;
	flex-direction: column;
	width: 240px;
}
.jackpots {
	width: 100%;
	height: 342px;
	padding: 30px 45px;
	background: linear-gradient(270deg, #9a51ff 57%, #2a065d 99.82%);
	background-size: cover;
	border-radius: 24px;
	display: flex;
	justify-content: space-between;
	position: relative;

	&:before {
		display: block;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: url(/nuxt-img/jackpot/bg.png) no-repeat 0 50%;
		background-size: cover;
		border-radius: 24px;

		@include media-breakpoint-down(lg) {
			background-position: 50% 0;
			border-radius: 10px;
		}
	}

	&--sweeps {
		background: linear-gradient(270deg, #e033ff 57.3%, #490055 100%);
	}

	@include media-breakpoint-down(lg) {
		height: 374px;
		padding: 37px 27px;
		border-radius: 10px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		overflow: hidden;

		&:before {
			background-image: url(/nuxt-img/jackpot/bg-mobile.png);
		}
	}
}
.jackpot-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	z-index: 0;
	min-width: 260px;

	@include media-breakpoint-down(lg) {
		align-items: center;
	}

	img {
		margin-right: 4px;
	}

	.title {
		position: relative;
		border-radius: 2px;
		border: 1px solid #fdbe3f;
		color: var(--primary);
		background: var(--gray-900);
		box-shadow: 0px 2px 24px 0px var(--gray-600) inset;
		padding: 2px 30px;
		margin: 0 16px;
		white-space: nowrap;

		@include media-breakpoint-down(md) {
			margin: 0 auto;
		}

		&:after,
		&:before {
			display: block;
			width: 34px;
			height: 34px;
			content: "";
			position: absolute;
			top: -2px;
			background: url(/nuxt-img/jackpot/diamond-title.png);
			background-size: contain;
		}

		&:after {
			right: -17px;
		}

		&:before {
			left: -17px;
		}
	}
}
.jackpot-all-win {
	display: flex;
	align-items: center;

	color: var(--neutral);
	z-index: 2;
	gap: 4px;
	margin: 16px 0 30px;

	@include media-breakpoint-down(lg) {
		margin: 8px 0 22px;
	}
}
.jackpots-block {
	width: 566px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 16px;
	position: relative;
	z-index: 5;

	@include media-breakpoint-up(lg) {
		margin-left: 16px;
	}

	&--desk {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	&--mob {
		display: none;
		@include media-breakpoint-down(lg) {
			width: calc(100% - 16px);
			display: block;
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
	}
	.jp-list-item {
		width: calc(50% - 8px);
		height: 133px;
		background: var(--a-overlay-100);
		border-radius: 12px;
		padding: 15px 20px;
		color: var(--neutral);

		&--diamond {
			background-color: var(--primary);
			color: var(--gray-900);
		}

		@include media-breakpoint-down(lg) {
			display: block;
			width: 100%;
			height: 115px;
			padding: 9px 20px;
			border-radius: 10px;
		}
	}
}
.jackpot {
	&__footer {
		@include media-breakpoint-down(lg) {
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.pagination {
	display: flex;
	gap: 15px;
	padding: 10px 0;
	justify-content: center;
	.dot {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: var(--neutral);
		opacity: 0.2;
		cursor: pointer;

		&:hover {
			opacity: 0.5;
		}

		&-active {
			opacity: 1;
		}
	}
}
</style>
